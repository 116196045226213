function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

function formatLeft(value) {
  if (value === '-') {
    return '-0'
  }
  let n = 0
  return value
    .split('')
    .reverse()
    .reduce((result, v, i) => {
      if (n === 3 && v !== '-' && i !== 0) {
        result.unshift(' ')
        n = 0
      }
      n++
      result.unshift(v)
      return result
    }, [])
    .join('')
}

function formatRight(value, decimals) {
  let result = value || ''
  for (let i = 0; i < decimals; i++) {
    if (result.length >= decimals) {
      break
    }
    result = `${result}0`
  }
  return result
}

export const formatNumber = function(value, decimals) {
  if (!value) {
    return '0'
  }
  let result = round(value, decimals ? decimals : 0)
    .toString()
    .split('.')
  if (decimals && result.length === 1) {
    result.push('')
  }
  return result.reduce((result, value, i) => {
    if (i === 0) {
      result = result + formatLeft(value)
    } else {
      const v = formatRight(value, decimals)
      result = result + (v ? ',' + v : '')
    }
    return result
  }, '')
}
