import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Icon } from 'components'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

async function node2pdf(node, width, height) {
  //const pxmm = document.getElementById('ppitest').offsetWidth / 25.4
  const heightpx = 1500 //height * pxmm
  const w = width - 25
  const ratio = node.clientHeight / node.clientWidth
  const h = w * ratio
  const pageCount = Math.ceil(h / height)

  const source = await html2canvas(node)
  const sourcectx = source.getContext('2d')
  const target = document.createElement('canvas')
  target.width = source.width
  target.height = source.height
  const targetctx = target.getContext('2d')

  const result = []
  let top = 0
  for (let i = 0; i < pageCount; i++) {
    let img = sourcectx.getImageData(0, top, source.width, heightpx)
    top = top + heightpx
    targetctx.putImageData(img, 0, 0)
    result.push({
      img: target.toDataURL('image/png'),
      w,
      h,
    })
  }
  return result
}

async function nodes2pdf(
  nodes,
  filename,
  width = 210,
  height = 297,
  left = 15,
  top = 10
) {
  let promises = []
  nodes.forEach(node => {
    promises.push(node2pdf(node, width, height - 2 * top))
  })
  let data2pdf = await Promise.all(promises)
  let pdf = new jsPDF('portrait', 'mm', 'a4')
  let h = top
  data2pdf.forEach(data => {
    data.forEach(data => {
      if (h + data.h > height) {
        pdf.addPage()
        h = top
      }
      pdf.addImage(data.img, left, h, data.w, data.h)
      h = h + data.h + top
    })
  })
  pdf.save(filename)
}

function PdfButton(props) {
  const { contentRef, icon, label, ...rest } = props
  const [running, setRunning] = useState(false)
  return (
    <>
      <Button
        disabled={running}
        {...rest}
        onClick={async () => {
          setRunning(true)
          try {
            await nodes2pdf(contentRef.current.childNodes, 'download.pdf')
          } finally {
            setRunning(false)
          }
        }}
      >
        {icon && !running && <Icon component={icon} />}
        {running ? 'Формирование PDF...' : label || 'Экспорт в PDF'}
      </Button>
      <div
        id="ppitest"
        style={{ width: '1in', visible: 'hidden', padding: '0px' }}
      ></div>
    </>
  )
}

export { PdfButton }
