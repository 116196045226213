import React from 'react'
import { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute, Preloader } from 'components'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import './AppLayout.css'

import routes from './AppLayout.routes'

import { setLocale } from '_core'

const NotFoundPage = lazy(() => import('pages/NotFoundPage'))

setLocale('ru')

class AppLayout extends React.Component {
  render() {
    return (
      <Suspense fallback={<Preloader />}>
        <Switch>
          {routes.map(route => {
            const RouteComponent = route.public ? Route : PrivateRoute
            return (
              <RouteComponent
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            )
          })}
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    )
  }
}

export { AppLayout }
