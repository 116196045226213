import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

class _Redirect extends React.Component {
  render() {
    return (
      <Redirect
        to={{ pathname: this.props.to, state: { from: this.props.location } }}
      />
    )
  }
}

_Redirect.propTypes = {
  to: PropTypes.string,
}

export { _Redirect as Redirect }
