export const formatSubtitle = function(subtitle, params) {
  subtitle = subtitle || ''
  params = params || {}
  params = Object.keys(params).reduce((result, key) => {
    try {
      result[key] =
        params[key]['value'] ||
        (typeof params[key] !== 'object' ? params[key] : '')
    } catch (err) {
      try {
        result[key] = params[key]
      } catch (err) {}
    }
    return result
  }, {})
  const exp = /:([a-zA-Z0-9_])+/g
  if (subtitle) {
    const keys = subtitle.match(exp) || []
    keys.forEach(p => {
      const exp = new RegExp(p, 'gi')
      const paramValue = params[p.substr(1).toUpperCase()]
      subtitle = subtitle.replace(exp, paramValue ? paramValue : '')
    })
  }
  return subtitle
}
