import React from 'react'

function Message(props) {
  let { color } = props
  return (
    <span
      className="lead align-baseline align-content-center"
      style={{ color: color || 'red' }}
    >
      {props.children}
    </span>
  )
}

export default Message
