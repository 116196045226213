import { combineReducers, createStore, compose, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { history } from './history'

import createModuleRegistry from './ModuleRegistry'

const combine = (reducers = {}) => {
  reducers['router'] = connectRouter(history)
  return combineReducers(reducers)
}

const configureStore = () => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(
    combine(),
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        createLogger()
      )
    )
  )
}

const reducerRegistry = createModuleRegistry(registry => {
  store.replaceReducer(combine(registry.getAll()))
})

export const store = configureStore()

store.addReducer = (name, reducer) => {
  reducerRegistry.register(name, reducer)
}
