import {
  locale,
  addLocale /*, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions*/,
} from 'primereact/api'

addLocale('ru', {
  firstDayOfWeek: 1,
  dayNames: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthNamesShort: [
    'Янв',
    'Фев',
    'Мар',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
  ],
  dateFormat: 'dd.mm.yy',
  today: 'Сегодня',
  clear: 'Очистить',
  emptyMessage: 'Нет данных',
})

export const setLocale = lc => {
  locale(lc)
}
