import io from 'socket.io-client'
import { auth } from 'modules/auth'
import { store } from '_core'
import { isJwtExpired } from 'jwt-check-expiration'

const IS_GATEWAY_URL = `${process.env.REACT_APP_GATEWAY_URL || ''}/is-gateway`

const SERVICE_IS_UNAVAILABLE = 'Сервис временно недоступен'

function createSocket() {
  return io(IS_GATEWAY_URL)
}

function getToken() {
  const token = localStorage.getItem(auth.TOKEN)
  if (!token || (token && isJwtExpired(token))) {
    const { dispatch } = store
    dispatch(auth.logout())
  }
  return token
}

export const Request = function(message) {
  const token = getToken() // Извлекаем токен из хранилища и проверяем срок годности токена
  return new Promise((resolve, reject) => {
    const socket = createSocket()
    socket.on('error', error => {
      socket.disconnect()
      console.error(error)
      reject(SERVICE_IS_UNAVAILABLE)
    })
    socket.on('response', data => {
      socket.disconnect()
      data.error ? reject(data.error) : resolve(data)
    })
    const { role } = message
    socket.emit('request', role === 'auth' ? message : { ...message, token })
  })
}
