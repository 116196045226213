class ModuleRegistry {
  constructor(listener) {
    this._modules = {}
    this._onRegister = listener
  }

  getAll() {
    return { ...this._modules }
  }

  get(name) {
    return this._modules[name]
  }

  setRegistryListener(listener) {
    this._onRegister = listener
  }

  register(name, module) {
    this._modules = { ...this._modules, [name]: module }
    if (this._onRegister) {
      this._onRegister(this, name, module)
    }
  }
}

export default (registryListener = false) => {
  return new ModuleRegistry(registryListener)
}
