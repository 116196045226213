export const testParams = function(params, filterValues = {}) {
  return Object.keys(params || {}).reduce((result, key) => {
    if (!params[key].hidden && !filterValues.hasOwnProperty(key)) {
      result = true
    }
    return result
  }, false)
}

export const getFilterValues = function(data) {
  const result = {}
  Object.keys(data).forEach(key => {
    const fv = data[key].filterValues || {}
    Object.keys(fv).forEach(key => {
      result[key] = fv[key]
    })
  })
  return result
}
