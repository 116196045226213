import c from './auth.constants'

const initialState = {
  token: false,
  fullName: 'Гость',
  name: 'guest',
  guid: false,
  check: false,
  error: false,
  count: 0,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case c.LOGIN_REQUEST:
      return {
        ...state,
        check: true,
        error: false,
      }
    case c.LOGIN_SUCCESS:
      return {
        ...action.payload.user,
        check: false,
        error: false,
      }
    case c.LOGIN_FAILURE:
      return {
        ...state,
        check: false,
        error: action.payload.message,
        count: state.count + 1,
      }
    case c.LOGOUT:
      const { count } = state
      return {
        ...initialState,
        error: state.error,
        count,
      }
    default:
      return state
  }
}

export default authReducer
