const IS_GATEWAY_API = `${process.env.REACT_APP_GATEWAY_URL || ''}/api`

export const RequestSync = function(message) {
  const params = Object.keys(message).reduce((result, key) => {
    result += `${result ? '&' : '?'}${key}=${message[key]}`
    return result
  }, '')
  const xhr = new XMLHttpRequest()
  xhr.open('GET', `${IS_GATEWAY_API}${params}`, false)
  xhr.send(null)
  return xhr.responseText
}
