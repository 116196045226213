import { Request } from '_core'

function login(username, password) {
  return Request({
    role: 'auth',
    cmd: 'login',
    username: username,
    password: password,
  })
}

function check_guid(guid) {
  return Request({
    role: 'auth',
    cmd: 'check_guid',
    guid: guid,
  })
}

function check_token(token) {
  return Request({
    role: 'auth',
    cmd: 'check_token',
    token: token,
  })
}

export default {
  login,
  check_guid,
  check_token,
}
