import React from 'react'
import Button from 'react-bootstrap/Button'
import { Icon, PrintButton, PdfButton } from 'components'
import { MdRefresh, MdPrint } from 'react-icons/md'
import { FaFilePdf } from 'react-icons/fa'

function PrintButtonPanel(props) {
  const { run, contentRef, disabled, pdf, hide } = props
  if (hide) {
    return null
  }
  return (
    <>
      <Button
        variant="primary"
        onClick={run}
        disabled={disabled}
        className="mt-3 mb-5"
      >
        {!disabled && <Icon component={MdRefresh} />}
        {disabled ? 'Обработка данных...' : 'Обновить'}
      </Button>
      <PrintButton
        contentRef={contentRef}
        variant="primary"
        className="ml-5 mt-3 mb-5"
        hidden={disabled}
        icon={MdPrint}
      />
      {pdf && (
        <PdfButton
          contentRef={contentRef}
          variant="primary"
          className="ml-5 mt-3 mb-5"
          hidden={disabled}
          icon={FaFilePdf}
        />
      )}
    </>
  )
}

export { PrintButtonPanel }
