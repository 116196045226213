import React from 'react'
import { Toast } from 'primereact/toast'

const summary = {
  success: 'Информация',
  info: 'Информация',
  warn: 'Предупреждение',
  error: 'Ошибка',
}

class MessageToast extends React.Component {
  constructor(props) {
    super(props)
    this.onShow = this.onShow.bind(this)
    this.onHide = this.onHide.bind(this)
  }
  onShow() {
    const { onHide } = this.props
    onHide && setTimeout(onHide, 1000)
  }
  onHide() {
    const { onHide } = this.props
    onHide && onHide()
  }
  componentDidUpdate(prevProps) {
    const { message, severity = 'info', life = 5000 } = this.props
    message &&
      message !== prevProps.message &&
      this.toast &&
      this.toast.show({
        life,
        severity,
        summary: summary[severity],
        detail: message,
      })
  }
  render() {
    const {
      onHide,
      position = 'bottom-right',
      message,
      severity,
      life,
      style,
      ...restProps
    } = this.props
    return (
      <Toast
        ref={el => (this.toast = el)}
        onShow={this.onShow}
        onHide={this.onHide}
        position={position}
        style={{ ...(style || {}), wordBreak: 'break-word' }}
        {...restProps}
      />
    )
  }
}

export { MessageToast }
