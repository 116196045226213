import React from 'react'
import ReactToPrint from 'react-to-print'
import Button from 'react-bootstrap/Button'
import { Icon } from 'components'

function PrintButton(props) {
  const { contentRef, margin, icon, label, ...rest } = props
  const pageStyle = `@page { size: auto;  margin: ${margin ||
    '20mm'}; } @media print { body { -webkit-print-color-adjust: exact; } }`
  return (
    <ReactToPrint
      pageStyle={pageStyle}
      content={() => contentRef.current}
      trigger={() => (
        <Button {...rest}>
          {icon && <Icon component={icon} />}
          {label || 'Печать'}
        </Button>
      )}
    />
  )
}

export { PrintButton }
