import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { store } from './_core'
import { history } from './_core'
import { AppLayout } from 'pages/AppLayout'

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppLayout />
      </ConnectedRouter>
    </Provider>
  )
}

export default App
