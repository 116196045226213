import React from 'react'
import { connect } from 'react-redux'

import { auth } from 'modules/auth'

import { Preloader } from 'components'

import './LoginPage.css'
import Logo from 'logo.svg'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = auth.getLoginFromLS()
    this.handleChange = this.handleChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.renderPage = this.renderPage.bind(this)
  }

  handleChange(e) {
    const { name, value, checked } = e.currentTarget
    this.setState({
      [name]: e.currentTarget.type === 'checkbox' ? checked : value,
    })
  }

  handleLogin(e) {
    e.preventDefault()
    const { username, password, rememberMe } = this.state
    const { dispatch } = this.props
    if (username && password) {
      dispatch(auth.login(username, password, rememberMe))
    } else {
      dispatch(
        auth.login_failure(
          `Укажите ${
            username ? 'пароль' : 'логин'
          } пользователя для входа в iSembler`
        )
      )
    }
  }

  renderPage() {
    const { username, password, rememberMe } = this.state
    const { error, count } = this.props
    return (
      <div className="container">
        <div className="row">
          <div className="form-login-container">
            <form
              className="form-login"
              name="loginForm"
              onSubmit={this.handleLogin}
            >
              <div className="text-center mb-4">
                {error && count > 1 && (
                  <div className="alert-container">
                    <div className="alert alert-danger">{error}</div>
                  </div>
                )}
                <img
                  className="mb-5 rounded"
                  src={Logo}
                  alt=""
                  width="72"
                  height="72"
                />
                <h1 className="h3 mb-3 font-weight-normal">iSembler</h1>
              </div>

              <div className="form-label-group">
                <input
                  type="text"
                  name="username"
                  className="form-control"
                  placeholder="Логин"
                  required
                  value={username}
                  onChange={this.handleChange}
                  autoFocus
                />
                <label htmlFor="username">Логин</label>
              </div>

              <div className="form-label-group">
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={this.handleChange}
                />
                <label htmlFor="password">Пароль</label>
              </div>

              <div className="checkbox mb-3">
                <label>
                  <input
                    type="checkbox"
                    name="rememberMe"
                    checked={rememberMe}
                    onChange={this.handleChange}
                  />
                  <span className="ml-2">Запомнить меня</span>
                </label>
              </div>

              <button className="btn btn-lg btn-primary btn-block">
                Вход в iSembler
              </button>
              <p className="mt-5 mb-3 text-muted text-center">
                &copy; ООО "Инфолайн", {new Date().getFullYear()}
              </p>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.inProgress) {
      return <Preloader />
    }
    return this.renderPage()
  }
}

const mapStoreToProps = store => ({
  inProgress: store.auth.check,
  error: store.auth.error,
  count: store.auth.count,
})

const ConnectedLoginPage = connect(mapStoreToProps)(LoginPage)
export { ConnectedLoginPage as LoginPage }
