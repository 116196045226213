import { Request } from '_core'

async function execute(cmd, data) {
  const params = {
    role: 'params',
    cmd,
    data,
  }
  try {
    const result = await Request(params)
    return result
  } catch (err) {
    console.error(err + '\n' + JSON.stringify(params))
  }
}

async function set(data) {
  return await execute('set', data)
}

async function get(data) {
  return await execute('get', data)
}

async function getall(data) {
  return await execute('getall', data)
}

export const paramStore = {
  set,
  get,
  getall,
}
