import { lazy } from 'react'
export default [
  {
    path: '/',
    component: lazy(() => import('pages/AboutPage')),
    exact: true,
    public: true,
  },
  {
    path: '/login',
    component: lazy(() => import('components/Login')),
    public: true,
  },
  {
    path: '/logout',
    component: lazy(() => import('components/Logout')),
    public: true,
  },
  {
    path: '/about',
    component: lazy(() => import('pages/AboutPage')),
    public: true,
  },
  {
    path: '/leds',
    component: lazy(() => import('pages/LedPage')),
  },
  {
    path: '/data',
    component: lazy(() => import('pages/DataPage')),
  },
  {
    path: '/view',
    component: lazy(() => import('pages/ViewPage')),
  },
  {
    path: '/gridview',
    component: lazy(() => import('pages/GridViewPage')),
  },
  {
    path: '/folders',
    component: lazy(() => import('pages/FoldersPage')),
  },
  {
    path: '/docview',
    component: lazy(() => import('pages/DocviewPage')),
  },
  {
    path: '/sembler',
    component: lazy(() => import('pages/SemblerPage')),
  },
]
