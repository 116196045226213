import React from 'react'
import { IconContext } from 'react-icons'

function Icon(props) {
  const { size = '23px', style = {}, component, children, ...rest } = props
  const Component = component
  return (
    <span
      style={{
        paddingRight: '10px',
        position: 'relative',
        top: '-2px',
        ...style,
      }}
      {...rest}
    >
      <IconContext.Provider value={{ size: size }}>
        {component && <Component />}
        {children}
      </IconContext.Provider>
    </span>
  )
}

export default Icon
