import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'

class ErrorMessage extends React.Component {
  render() {
    return (
      <Container className="mt-5">
        <Alert variant="danger" className="mt-5">
          <Alert.Heading>Ошибка</Alert.Heading>
          <hr />
          <p className="lead" style={{ wordWrap: 'break-word' }}>
            {this.props.message}
          </p>
        </Alert>
      </Container>
    )
  }
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export { ErrorMessage }
