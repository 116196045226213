import c from './auth.constants'
import auth_service from './auth.service'

function login_request() {
  return {
    type: c.LOGIN_REQUEST,
  }
}

function logout() {
  removeTokenFromLS()
  return {
    type: c.LOGOUT,
  }
}

function login_success(user) {
  saveTokenToLS(user.token)
  return {
    type: c.LOGIN_SUCCESS,
    payload: {
      user: user,
    },
  }
}

function login_failure(error) {
  removeTokenFromLS()
  return {
    type: c.LOGIN_FAILURE,
    payload: {
      message: error,
    },
  }
}

function addLoginToLS(username, password) {
  localStorage.setItem(
    c.LOGIN,
    JSON.stringify({
      username: username,
      password: password,
      rememberMe: true,
    })
  )
}

function removeLoginFromLS() {
  localStorage.removeItem(c.LOGIN)
}

function getLoginFromLS() {
  let user
  try {
    user = JSON.parse(localStorage.getItem(c.LOGIN))
  } catch (err) {}
  return (
    user || {
      username: '',
      password: '',
      rememberMe: false,
    }
  )
}

function saveLoginToLS(username, password, rememberMe) {
  if (rememberMe === true) {
    addLoginToLS(username, password)
  } else {
    if (rememberMe === false) {
      removeLoginFromLS()
    }
  }
}

function login(username, password, rememberMe) {
  return dispatch => {
    dispatch(login_request())
    auth_service.login(username, password).then(
      user => {
        dispatch(login_success(user))
        saveLoginToLS(username, password, rememberMe)
      },
      error => dispatch(login_failure(error))
    )
  }
}

function check_guid(guid) {
  return dispatch => {
    dispatch(login_request())
    auth_service.check_guid(guid).then(
      user => {
        dispatch(login_success(user))
      },
      error => dispatch(login_failure(error))
    )
  }
}

function saveTokenToLS(token) {
  localStorage.setItem(c.TOKEN, token)
}

function getTokenFromLS() {
  return localStorage.getItem(c.TOKEN)
}

function removeTokenFromLS() {
  localStorage.removeItem(c.TOKEN)
}

function check_token() {
  return dispatch => {
    dispatch(login_request())
    auth_service.check_token(getTokenFromLS()).then(
      user => {
        dispatch(login_success(user))
      },
      error => dispatch(login_failure(error))
    )
  }
}

export default {
  ...c,
  login_request,
  logout,
  login,
  check_guid,
  login_failure,
  getLoginFromLS,
  check_token,
  getTokenFromLS,
}
