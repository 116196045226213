import React from 'react'
import './Preloader.css'

class Preloader extends React.Component {
  render() {
    return (
      <div className="preloader" {...this.props}>
        <div className="clear-load preloader-load">
          <span></span>
        </div>
      </div>
    )
  }
}

/*import { ProgressSpinner } from 'primereact/progressspinner'
function Preloader() {
  return (
    <div className="flex align-items-stretch justify-content-between h-100 w-100">
      <ProgressSpinner className="m-auto h-3rem" strokeWidth={4} />
    </div>
  )
}*/

export { Preloader }
