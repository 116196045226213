import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Preloader } from 'components'
import { auth, LoginPage } from 'modules/auth'
import { history } from '_core'

class PrivateRoute extends React.Component {
  getLoginParams(searchParams, ...loginParams) {
    const params = {}
    loginParams.map(name => {
      params[name] = searchParams.get(name)
      return true
    })
    return params
  }

  loginByUserNamePassword(params) {
    if (params.username && params.password) {
      const { dispatch } = this.props
      dispatch(auth.login(params.username, params.password, undefined))
      return true
    }
    return false
  }

  loginByGuid(params) {
    if (params.guid) {
      const { dispatch } = this.props
      dispatch(auth.check_guid(params.guid))
      return true
    }
    return false
  }

  removeLoginFromSearchParams(location, params, ...loginParams) {
    loginParams.map(item => {
      if (params.has(item)) {
        params.delete(item)
      }
      return true
    })
    let search = params.toString()
    history.replace(`${location.pathname}${search ? '?' : ''}${search}`)
  }

  loginBySearchParams(...loginParams) {
    const { location } = this.props
    const searchParams = new URLSearchParams(location.search)
    const params = this.getLoginParams(searchParams, ...loginParams)
    if (this.loginByUserNamePassword(params) || this.loginByGuid(params)) {
      this.removeLoginFromSearchParams(location, searchParams, ...loginParams)
      return true
    }
    return false
  }

  componentDidMount() {
    if (!this.loginBySearchParams('username', 'password', 'guid')) {
      const { dispatch } = this.props
      dispatch(auth.check_token())
    }
  }

  render() {
    let { component: Component, auth, ...rest } = this.props
    if (auth.check) {
      return <Preloader />
    } else {
      return auth.token ? (
        <Route {...rest} component={Component} />
      ) : (
        <LoginPage />
      )
    }
  }
}

const mapStoreToProps = store => ({
  auth: store.auth,
})

const connectedPrivateRoute = connect(mapStoreToProps)(PrivateRoute)
export { connectedPrivateRoute as PrivateRoute }
